import React from "react";

export const PrivacyContent = () => {
  return (
    <div className="common_width PrivacyContent">
      <h1>Privacy Policy</h1>
      <h2>Welcome to Sabmilay!</h2>
      <p>
        Sabmilay values your privacy and the protection of your personal data.
        This privacy policy describes what information we collect from you, how
        we collect it, how we use it, how we obtain your consent, how long we
        retain it in our databases and, if necessary, with whom we share it.
      </p>
      <p>
        By registering as a user and using the platform, you are accepting the
        practices described in this privacy policy. Your use of the platform is
        also subject to our terms and conditions. In this privacy policy, the
        word "platform" refers to the Sabmilay App and website together, "we",
        "us", "our" and "Sabmilay" refer to Sabmilay and "you" and "user" refer
        to you, the Sabmilay user.
      </p>
      <p>
        This privacy policy may change from time to time. Your continued use of
        the platform after we make changes is deemed acceptance of those
        changes, so please check the policy periodically for updates. This
        privacy policy has been prepared and is maintained in accordance with
        all applicable national and international laws and regulations and,
        specifically, with the California Consumer Privacy Act (CCPA) and the
        General Data Protection Regulation (GDPR - European Regulations).
      </p>
      <h2>GENERAL INFORMATION</h2>
      <p>
        The personal data of users that are collected and processed through:
      </p>
      <ul>
        <li>
          <a href="https://sabmilay.com">https://sabmilay.com</a>
        </li>
        <li>Will be under responsibility and in charge of:</li>
      </ul>
      <p>Will be under responsibility and in charge of:</p>
      <ul>
        <li>Sabmilay</li>
        <li>contactus@sabmilay.com</li>
      </ul>

      <h2>TYPES OF INFORMATION GATHERED</h2>
      <p>
        The information we collect from our users helps us to deliver our
        services effectively and to personalize and continually improve the
        user's experience on the platform. These are the types of information we
        collect:
      </p>
      <p>
        <b>Information You Give Us.</b> You provide information when you
        provide, search, read and view content through the platform, register as
        a user (sellers and buyers), purchase a subscription, post products and
        content on the platform, place an order and/or communicate with us
        through our contact information or contact forms. As a result of those
        actions, you might supply us with the following information:
      </p>
      <ul>
        <li>First and last name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Address</li>
        <li>User content</li>
        <li>
          Any additional information relating to you that you provide to us
          directly or indirectly through our platform or online presence such as
          ‘cookies’.
        </li>
      </ul>
      <p>
        Sabmilay will not collect any personally identifiable information about
        you, unless you provide it.
      </p>

      <p>
        <b>Information Collected Automatically:</b> By accessing and using the
        platform you automatically provide us with the following information:
      </p>

      <ul>
        <li>The device and usage information you use to access the platform</li>
        <li>Your IP address </li>
        <li>Browser and device characteristics</li>
        <li>Operating system</li>
        <li>Referring URLs</li>
        <li>What parts of the platform you use and how often</li>
      </ul>
      <p>
        If you access the platform through a mobile phone, we will collect the
        following information:
      </p>

      <ul>
        <li>Mobile device ID</li>
        <li>Model and manufacturer</li>
        <li>Operating system </li>
        <li>Version information</li>
        <li>IP address</li>
      </ul>
      <p>
        <b>Location:</b> We store real-time information about the location of
        users registered on the platform when they use some of the
        functionalities available on the platform, by accessing the GPS function
        built into the mobile devices of each user. This information is stored
        to facilitate the correct use of the platform and to provide specific
        services based on the user's location. However, this information does
        not identify or individualize the person, it is completely anonymous.
        The user can request the removal of such information but certain
        functionalities of the platform would not be available. Location
        information collected through the platform will be treated in accordance
        with this privacy policy.
      </p>
      <p>
        will be treated in accordance with this privacy policy. Payment
        information: Your credit card or payment card data will be processed by
        the payment processors available on the platform (Payoneer, Stripe),
        which will process and store your data securely and for the sole purpose
        of processing transactions within the platform. Sabmilay reserves the
        right to contract any payment platform available on the market, which
        treats your data for the sole purpose of processing transactions within
        the platform.
      </p>
      <p>See the privacy policy of Payoneer and Stripe here:</p>
      <ul>
        <li>
          <a href="https://stripe.com/gb/privacy">Stripe</a>
        </li>
        <li>
          <a href="https://www.payoneer.com/legal/privacy-policy/">Payoneer</a>
        </li>
      </ul>
      <p>
        <b>GOOGLE Analytics.</b> We use Google Analytics provided by Google,
        Inc., USA (“Google”). These tool and technologies collect and analyze
        certain types of information, including IP addresses, device and
        software identifiers, referring and exit URLs, feature use metrics and
        statistics, usage and purchase history, media access control address
        (MAC Address), mobile unique device identifiers, and other similar
        information via the use of cookies. The information generated by Google
        Analytics (including your IP address) may be transmitted to and stored
        by Google on servers in the United States. We use the GOOGLE Analytics
        collection of data to enhance the platform and improve our service.
      </p>
      <p>Please consult Google's privacy policy here:</p>
      <ul>
        <li>
          <a href="https://policies.google.com/privacy">Google Policy</a>
        </li>
      </ul>
      <p>
        Facebook Pixel: Our platform uses the Facebook Pixel. Through the
        Facebook Pixel we can collect user information for different purposes.
        We use the Facebook Pixel for the following purposes:
      </p>

      <ul>
        <li>
          Collect statistics about our platform (for example, the number of
          users who visited a page).
        </li>
        <li>
          Collect information about how you interact with our platform (for
          example, whether you opened or followed links contained in them).
        </li>
        <li>Personalize online services and marketing communications.</li>
        <li>
          Tailor advertisements to users and optimize advertising campaigns.
        </li>
      </ul>
      <p>
        The information collected through the Facebook Pixel will be collected
        and stored by Facebook and will be treated in accordance with its
        privacy policy. The information we collect through the Facebook Pixel
        does not personally identify the user and will never be used for
        purposes other than those contained in this privacy policy and
        Facebook's privacy policy.
      </p>
      <p>Please consult Facebook's privacy policy here:</p>
      <ul>
        <li>
          <a href="https://www.facebook.com/privacy/explanation">Facebook</a>
        </li>
      </ul>
      <p>
        <b>Social Media:</b> On our platform you will find links and functions
        linked to different social media, in which you can share your
        information.
      </p>
      <p>
        It is advisable to consult the privacy policy and data protection of
        each social media used on our platform.
      </p>

      <ul>
        <li>
          <a href=" https://www.facebook.com/privacy/explanation">Facebook</a>
        </li>
        <li>
          <a href="http://instagram.com/about/legal/privacy/">Instagram</a>
        </li>
      </ul>

      <h2>HOW LONG WE KEEP YOUR DATA</h2>
      <p>
        Personal data provided by users through the platform will be retained
        for as long as necessary to provide the platform and the functionalities
        available on the platform or until the user retains the user account on
        the platform or decides to close it or until Sabmilay closes and deletes
        the user account. Sabmilay may retain personal data for a longer period
        where the user has given consent to such processing, provided that such
        consent is not withdrawn. In addition, Sabmilay may be obliged to retain
        personal data for a longer period if this is necessary for compliance
        with a legal obligation or by order of an authority. Once the retention
        period expires, the personal data will be deleted. Therefore, the right
        of access, the right of erasure, the right of rectification and the
        right to data portability cannot be asserted once the retention period
        has expired.
      </p>

      <h2>HOW WE USE YOUR INFORMATION.</h2>
      <p>
        In general, we use the information we collect primarily to provide,
        maintain, protect and improve our platform and services. We use personal
        information collected through our platform as described below:
      </p>

      <ul>
        <li>Provide the platform (Available on Google Play and App store).</li>
        <li>Identify you as a user in our system.</li>
        <li>User registration (sellers and buyers).</li>
        <li>Provide the subscriptions.</li>
        <li>Publish products and seller's content.</li>
        <li>Process transactions within the platform.</li>
        <li>Facilitate communications between users.</li>
        <li>
          Improve our services, platform, and how we operate our business.
        </li>
        <li>
          Understand and enhance your experience using our platform and
          services.
        </li>

        <li>Respond to your comments or questions through our support team.</li>
        <li>
          Send you related information, including confirmations, invoices,
          technical notices, updates, security alerts and support and
          administrative messages.
        </li>
        <li>
          Communicate with you about upcoming events, offers and news about
          products and services offered by Sabmilay and our selected partners.
        </li>
        <li>Marketing purposes of Sabmilay.</li>
        <li>
          Link or combine your information with other information we get from
          third parties to help understand your needs and provide you with
          better service.
        </li>
        <li>
          Protect, investigate and deter against fraudulent, unauthorized or
          illegal activity.
        </li>
      </ul>

      <h2>HOW DO YOU GET MY CONSENT?</h2>
      <p>
        By registering as a user (sellers or buyers), purchasing a subscription,
        posting products and content on the platform, placing an order,
        communicating with us through our contact forms or our contact
        information, and providing us with personal information to communicate
        with you, you consent to our use of cookies, you consent to our
        collection, storage and use of your information on the terms contained
        in this privacy policy. You may withdraw your consent by sending us your
        request via the contact information or contact page.
      </p>

      <h2>HOW WE SHARE INFORMATION</h2>
      <p>
        The personal information of our users is an important and fundamental
        part of our business. Under no circumstances will we sell or share
        information with third parties that has not been previously authorized
        by the user or owner of the personal data. We share user information
        solely and exclusively as described below.
      </p>
      <p>
        <b>Third-Party Service Providers.</b> We use third-party services to
        perform certain functions on our behalf and through our platform and
        services. Examples include processing payments (Stripe, Payoneer),
        sending emails, analyzing data (Google Analytics), creating ads
        (Facebook, Google), providing marketing assistance and delivering search
        results.
      </p>
      <p>
        These third-party services and tools may have access to personal
        information needed to perform their functions, but may not use that
        information for other purposes. Information shared with these
        third-party services will be treated and stored in accordance with their
        respective privacy policies and our privacy policy.
      </p>
      <p>
        <b>Email marketing:</b> We use the information provided by users to
        conduct marketing campaigns and send relevant information to our users.
        By registering as a user on the platform, you authorize us to use your
        information for email marketing. We will use third party services to
        perform email marketing, so we may share certain information with some
        of these third parties for the sole and exclusive purpose of sending
        emails through email marketing and in accordance with our privacy
        policy.
      </p>
      <p>
        <b>Business Transfers.</b> In the event that Sabmilay creates, merges
        with, or is acquired by another entity, your information will most
        likely be transferred. Sabmilay will email you or place a prominent
        notice on our platform before your information becomes subject to
        another privacy policy.
      </p>
      <p>
        <b>Protection of Sabmilay and others</b>. We release personal
        information when we believe release is appropriate to comply with the
        law, enforce or apply our Terms and conditions and other agreements, or
        protect the rights, property, or safety of Sabmilay, our users or
        others. This includes exchanging information with other companies and
        organizations for fraud protection and credit risk reduction.
      </p>
      <p>
        <b>With Your Consent.</b> Other than as set out above, you will receive
        notice when personally identifiable information about you might go to
        third parties, and you will have an opportunity to choose not to share
        the information.
      </p>
      <p>
        <b>Anonymous Information</b>. Sabmilay uses the anonymous browsing
        information collected automatically by our servers primarily to help us
        administer and improve the platform. We may also use aggregated
        anonymous information to provide information about the platform to
        potential business partners and other unaffiliated entities. This
        information is not personally identifiable.
      </p>
      <p>
        <b>Email Address</b>. The email address that you supply to us for
        purposes of receiving our email communications will never be rented or
        sold to a third party.
      </p>

      <h2>PROTECTING YOUR INFORMATION</h2>

      <p>
        We work to protect the security of your information during transmission
        by using Secure Sockets Layer (SSL) software, which encrypts information
        you provide or share through the platform. If transactions are processed
        on the platform, transaction information is transmitted to and from the
        platform in encrypted form using industry-standard SSL connections to
        help protect such information from interception. We restrict authorized
        access to your personal information to those persons who have a
        legitimate need to know such information to provide certain functions
        and to those persons you have authorized to have access to such
        information. Sabmilay follows generally accepted industry standards for
        data security to protect the personal information you provide and share
        through the platform, both during transmission and once Sabmilay
        receives it. No method of transmission over the Internet, or method of
        electronic storage, is 100% secure. Therefore, while Sabmilay strives to
        use commercially acceptable means to protect your personal information,
        we cannot guarantee its absolute security. We will not sell, distribute
        or lease your personal information to third parties unless we have your
        permission or are required by law to do so.
      </p>

      <h2>RIGHTS</h2>
      <p>
        Users who provide information through our platform, as data subjects and
        data owners, have the right to access, rectify, download or delete their
        information, as well as to restrict and object to certain processing of
        their information. While some of these rights apply generally, others
        apply only in certain limited circumstances. We describe these rights
        below:
      </p>
      <ul>
        <li>
          <b> Access and portability:</b> to access and know what information is
          stored in our servers, you can send us your request through our
          contact information.
        </li>
        <li>
          <b> Rectify, Restrict, Limit and/or Delete:</b> You can also rectify,
          restrict, limit or delete much of your information.
        </li>

        <li>
          <b>Right to be informed:</b> Users of our platform will be informed,
          upon request, about what data we collect, how it is used, how long it
          is retained and whether it is shared with third parties.
        </li>

        <li>
          <b>Object:</b> When we process your information based on our
          legitimate interests as explained above, or in the public interest,
          you may object to this processing in certain circumstances. In such
          cases, we will stop processing your information unless we have
          compelling legitimate reasons to continue processing it or where it is
          necessary for legal reasons.
        </li>
        <li>
          <b>Revoke consent:</b> Where you have previously given your consent,
          such as to allow us to process and store your personal information,
          you have the right to revoke your consent to the processing and
          storage of your information at any time. For example, you may withdraw
          your consent by updating your settings. In certain cases, we may
          continue to process your information after you have withdrawn your
          consent if we have a legal basis for doing so or if your withdrawal of
          consent was limited to certain processing activities.
        </li>
        <li>
          <b>Complaint:</b> If you wish to file a complaint about our use of
          your information (and without prejudice to any other rights you may
          have), you have the right to do so with your local supervisory
          authority. Users can exercise all these rights by contacting us
          through the contact information or the contact page.
        </li>

        <li>
          <b>
            Rights related to automated decision-making, including profiling:
          </b>
          platform users may request that we provide a copy of the automated
          processing activities we conduct if they believe that data is being
          unlawfully processed.
        </li>
      </ul>
      <p>
        Users or owners of the personal information they provide through the
        platform may exercise these rights over their personal information at
        any time and without any limitation by sending us their request through
        our contact information.
      </p>
      <h2>CHILDREN’S ONLINE PRIVACY PROTECTION</h2>
      <p>
        We comply with the requirements of the California Consumer Privacy Act
        (CCPA) and the General Data Protection Regulation (GDPR - European
        Regulations), regarding the protection of personal data of minors. We do
        not collect information from children under the age of 13. In the event
        that a child under the age of 13 provides us with personal information,
        we will take immediate steps to delete such information.
      </p>

      <h2>THIRD PARTIES</h2>
      <p>
        Except as otherwise expressly included in this Privacy Policy, this
        document addresses only the use and disclosure of information Sabmilay
        collects from you. If you disclose your information to others, whether
        other Sabmilay users or vendors, different rules may apply to their use
        or disclosure of the information you disclose to them. Sabmilay does not
        control the privacy policies of third parties, and you are subject to
        the privacy policies of those third parties where applicable. Sabmilay
        is not responsible for the privacy or security practices of other
        platforms, including those that are linked to from Sabmilay.
      </p>

      <h2>CONTACT US</h2>
      <p>
        If you have any questions or concerns about this privacy policy and the
        processing and security of your data, please contact us via our contact
        page or by using the following contact information:
      </p>
    </div>
  );
};

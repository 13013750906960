import React from "react";
import port_big from "../assets/img/mbl_analyse 1.svg";
export const Portfolio = () => {
  return (
    <section className="portfilio">
      <div className="common_width">
        <img src={port_big} alt="" />
      </div>
    </section>
  );
};

import React from "react";
import "../assets/css/Team.css";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { EmailSender } from "../components/EmailSender";
import { TeamCard } from "../components/TeamCard";
import imageteam from "../assets/img/0.jpg";
import web from "../assets/img/web.jpeg";

const Team = () => {
  return (
    <div className="Landing Team">
      <div className="landing_wrapper">
        <div className="firstcircle circle "></div>
        <div className="secondcircle circle "></div>

        {/* Header */}
        <Header />

        <div className="team_wrapper common_width">
          <h1 style={{ textAlign: "left" }}>Our Team</h1>
          <p>
            Although our company is small. We strive to have a diverse community
            working at Sabmilay.
          </p>
          <div
            className="cards_wrapper"
            style={{ position: "relative", zIndex: 5 }}
          >
            <TeamCard
              img={imageteam}
              name="Mohammad Sameer "
              work="Founder, IOS Engineer at Sabmilay."
            />
            <TeamCard
              img={web}
              name="Atif Asim"
              work="Front end Web developer"
            />
          </div>
        </div>
      </div>

      <EmailSender />

      <Footer />
    </div>
  );
};
export default Team;

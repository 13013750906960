import React from "react";

export const TermsContent = () => {
  return (
    <div className="common_width PrivacyContent">
      <h1>Terms And Conditions</h1>
      <h2>Welcome to Sabmilay!</h2>
      <p>These are the terms and conditions for:</p>
      <a href="#">https://sabmilay.com</a>
      <p>
        By using the platform, you agree to be bound by these terms and
        conditions and our privacy policy. In these terms and conditions, the
        word "platform" refers to the Sabmilay App and website together, "we",
        "us", "our" and "Sabmilay" refer to Sabmilay and "you" and "user" refer
        to you, the Sabmilay user.
      </p>
      <p>
        The following terms and conditions apply to your use of the platform.
        This includes mobile and tablet versions, as well as any other version
        of Sabmilay accessible via desktop, mobile, tablet, social media or
        other devices.
      </p>
      <p>
        PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING, POSTING
        INFORMATION ON, OR OBTAINING ANY SERVICES OR PRODUCTS FROM THE PLATFORM.
      </p>
      <h2>1. ACCEPTANCE OF TERMS</h2>
      <p>
        This agreement sets forth legally binding terms for your use of
        Sabmilay. By registering and using the platform, you agree to be bound
        by this agreement. If you do not accept the terms of this agreement, you
        should not use the platform and discontinue use of the service
        immediately. We may modify this agreement from time to time, and such
        modification shall be effective upon its posting on the platform. You
        agree to be bound by any modification to this terms and conditions when
        you use Sabmilay after any such modification is posted; it is therefore
        important that you review this agreement regularly.
      </p>
      <p>
        Use of the platform is prohibited for children under the age of 13. In
        the case of children under 18 and over 13, it is the responsibility of
        parents and legal guardians to determine whether use of the platform or
        any of the content and functionality available on the platform is
        appropriate for their child or minor in custody.
      </p>
      <p>
        You represent and warrant that all registration information you submit
        is accurate and truthful; and that your use of the platform does not
        violate any applicable law or regulation. Sabmilay may, in its sole
        discretion, refuse to offer the services to any user and change its
        eligibility criteria at any time. This provision is void where
        prohibited by law and the right to access the service and the platform
        is revoked in such jurisdictions.
      </p>
      <p>
        The platform may only be used in compliance with these terms and all
        applicable local, state, national and international laws, rules and
        regulations.
      </p>
      <p>
        By registering and using the platform, you represent and warrant that
        you have the full right, power and authority to enter into this
        agreement and to fully perform all of your obligations hereunder. You
        further represent and warrant that you are under no legal disability or
        contractual restriction that prevents you from entering into this
        agreement.
      </p>
      <h2>2. SELLERS</h2>
      <h5>2.1. Registration</h5>
      <p>
        By registering and using the platform as a seller user, you represent
        and warrant that you accept the conditions contained in these terms and
        conditions and agree to (a) provide true, accurate, current and complete
        information about yourself and your products as prompted by the
        registration form available from Sabmilay and (b) maintain and promptly
        update your registration data to keep it true, accurate, current and
        complete (c) own the commercial rights or licenses to the products
        posted on Sabmilay. If we have reasonable grounds to suspect that such
        data is untrue, inaccurate, not current or incomplete, Sabmilay reserves
        the right to suspend or terminate your account, remove your products
        from the platform and refuse any and all current or future use of the
        service (or any part thereof) at any time without notice.
      </p>
      <p>
        By using the platform, you represent and warrant that the products you
        post through the advertisements available on Sabmilay comply with all
        applicable laws and regulations.
      </p>
      <p>
        Users may share personal and commercial information through the platform
        with other users. Any information that users share through the platform
        is the sole responsibility of the users themselves. Users are free to
        share information, but are responsible for the use of such information,
        its publication and disclosure. Sabmilay is not responsible for
        information posted and shared through the platform. The information you
        provide and publish through the platform will be visible to the general
        public.
      </p>
      <h5>2.2. Account</h5>
      <p>
        If you register on Sabmilay, you will be required to choose a password,
        and you may be asked for additional information regarding your account.
        You are responsible for maintaining the confidentiality of your password
        and account information, and are fully responsible for all activities
        that occur under your password or account. You agree to (a) immediately
        notify Sabmilay of any unauthorized use of your password or account or
        any other breach of security, and (b) ensure that you log out from your
        account at the end of each session. You may never use another user’s
        account without prior authorization from Sabmilay. Sabmilay will not be
        liable for any loss or damage arising from your failure to comply with
        this agreement.
      </p>
      <p>
        By providing Sabmilay with your email address, you consent to our use of
        your email address to send you notices about Sabmilay. We may also use
        your email address to send you notifications, push notifications and
        other messages, such as changes to service features, news, and special
        content. If you do not wish to receive these emails, you may opt-out of
        receiving them by sending us your withdrawal request via the contact
        information or by using the "unsubscribe" option in the emails. Opting
        out may prevent you from receiving emails about updates, news or special
        content.
      </p>
      <p>
        Users may cancel their accounts at any time and for any reason by
        following the instructions on the platform or by sending us their
        request through our contact information. That termination will only
        result in the deletion of the account and the deletion of all the
        personal data granted to Sabmilay.
      </p>
      <p>
        Sabmilay reserves the right to terminate your account or your access
        immediately, with or without notice to you, and without liability to
        you, if Sabmilay believes that you have breached any of these terms,
        furnished Sabmilay with false or misleading information, or interfered
        with use of the platform or the service by others.
      </p>
      <h5>2.3. Subscriptions</h5>
      <p>
        Sabmilay offers monthly subscriptions that will allow the seller to
        publish more products on the platform according to the type of
        subscription and access to special statistics. When a user makes the
        purchase of a subscription, Sabmilay will send a confirmation email.
        This confirmation email will be produced automatically so that the user
        has confirmation of the payment and the start of the subscription. If
        the user does not receive the confirmation email upon purchase and start
        of the subscription, it is possible that it has been sent to your spam
        folder.
      </p>
      <p>
        Sabmilay may cancel the sale of any subscription and may change or
        discontinue the availability of subscriptions at any time at its sole
        discretion. If a subscription purchase is cancelled, the payment made
        for the subscription will be refunded in the applicable billing period.
        This does not affect your statutory rights.
      </p>
      <p>
        Subscriptions include automatic recurring payments. Unless it is the
        free subscription, subscriptions will be billed monthly or annually if
        the user wishes to obtain a discount. You authorize Sabmilay to renew
        your subscription and to charge you periodically and progressively on
        each billing date. The subscription billing date is the date on which
        you purchase the subscription and make the first payment. On the
        corresponding billing date, you will automatically be charged the
        corresponding subscription fee. The subscription will remain active
        until you cancel it or we terminate it. You must cancel your
        subscription before it renews to avoid the next billing period. We will
        bill you for the subscription billing fee in the payment method you
        choose during registration and subscription purchase.
      </p>
      <p>
        Subscriptions will automatically renew for an additional period unless
        cancelled prior to the next billing period. To cancel subscriptions,
        users must submit a cancellation request through our contact information
        and the subscription will be cancelled for the next billing period. If a
        subscription is cancelled, the user may continue to use the platform
        payment features for the subscription until the next billing period, at
        which time the subscription and access to the platform payment features
        will be cancelled.
      </p>
      <h5>2.4. Payments</h5>
      <p>Subscriptions can be paid through the following payment processors:</p>
      <ul>
        <li>Payoneer</li>
        <li>Stripe</li>
      </ul>
      <p>
        The subscription payment will be charged to your credit/debit card
        immediately after completing the subscription payment and registration
        process. The subscription will be activated upon completion of the
        payment and registration process and will be charged on each billing
        date automatically. Once the transaction is processed, we will send an
        electronic invoice to the user's email address.
      </p>
      <p>
        If you find any inconsistencies in your billing, please contact us via
        our contact details or you can make a complaint via the Buyer service of
        the relevant payment platform.
      </p>
      <p>
        If your card is declined, you will receive an error message. No payment
        will be charged to your card and no order will be processed. There may
        be a pending transaction on your account until your card issuing bank
        withdraws the authorization. This usually takes 2 to 5 working days.
        Your card may be declined for a number of reasons, such as insufficient
        funds, AVS (Address Verification System) mismatch or you have entered an
        incorrect security code.
      </p>
      <p>
        If your payment is declined, you will need to provide an alternative
        payment method or provide another card on which the payment can be
        charged and processed.
      </p>
      <p>
        Your payment details will be treated and retained securely and for the
        sole purpose of processing the purchase of the subscriptions. Sabmilay
        reserves the right to engage any payment platform available on the
        market, which processes your data for the sole purpose of processing the
        purchase of the subscriptions.
      </p>
      <h1>3. BUYERS</h1>
      <h5>3.1. Registration</h5>
      <p>
        By registering on the platform, you represent and warrant that you
        accept the terms contained in these terms and agree to (a) provide true,
        accurate, current and complete information about yourself as prompted by
        the registration form available on Sabmilay, and (b) maintain and
        promptly update your registration data to keep it true, accurate,
        current and complete. If we have reasonable grounds to suspect that such
        data is untrue, inaccurate, not current or incomplete, Sabmilay reserves
        the right to suspend or terminate your account and refuse any and all
        current or future use of the platform at any time without notice.
      </p>
      <p>
        Users may share personal and business information through the platform
        with other users. Any information that users share through the platform
        is the sole responsibility of the users themselves. Users are free to
        share information, but are responsible for the use of such information,
        its publication and disclosure. Sabmilay is not responsible for the
        information you provide and share through the platform. The information
        you provide and share through the platform is the sole and exclusive
        responsibility of the users.
      </p>
      <h5>3.2. Account</h5>
      <p>
        If you register on Sabmilay, you will be required to choose a password,
        and you may be asked for additional information regarding your account.
        You are responsible for maintaining the confidentiality of your password
        and account information, and are fully responsible for all activities
        that occur under your password or account. You agree to (a) immediately
        notify Sabmilay of any unauthorized use of your password or account or
        any other breach of security, and (b) ensure that you log out from your
        account at the end of each session. You may never use another user’s
        account without prior authorization from Sabmilay. Sabmilay will not be
        liable for any loss or damage arising from your failure to comply with
        this agreement.
      </p>
      <p>
        By providing Sabmilay with your email address and phone number you
        consent to our use of your email address to send you notices about
        Sabmilay. We may also use your email address and phone number to send
        you notifications, push notifications and other messages, such as
        changes to service features, news, and special content. If you do not
        wish to receive these emails, you may opt-out of receiving them by
        sending us your withdrawal request via the contact information or by
        using the "unsubscribe" option in the emails or mobile notifications.
        Opting out may prevent you from receiving emails about updates, news or
        special content.
      </p>
      <p>
        Users may cancel their accounts at any time and for any reason by
        following the instructions on the platform or by sending us their
        request through our contact information. That termination will only
        result in the deletion of the account and the deletion of all the
        personal data granted to Sabmilay.
      </p>
      <p>
        Sabmilay reserves the right to terminate your account or your access
        immediately, with or without notice to you, and without liability to
        you, if Sabmilay believes that you have breached any of these terms,
        furnished Sabmilay with false or misleading information, or interfered
        with use of the platform or the service by others.
      </p>
      <h5>3.3. Purchases on the Platform</h5>
      <p>
        By placing an order, you offer to purchase the products for the price
        advertised and indicated on the platform at the time of purchase. Please
        check the features and variants of the products, such as prices, colors
        and specifications, before placing an order. Please also check the
        shipping and return policies of each seller before placing an order.
      </p>
      <p>
        When a customer places an order, Sabmilay will send an e-mail whose
        purpose is to confirm the purchase and payment. This e-mail confirmation
        will occur automatically so that the user has confirmation of your
        purchase and order details.
      </p>
      <p>
        Sabmilay may cancel any order and may change or discontinue products at
        any time at its sole discretion. If an order is canceled, any payment
        made will be refunded in full. This does not affect your statutory
        rights.
      </p>
      <p>
        All new orders are considered separately and each is treated
        individually.
      </p>
      <h5>3.4. Pricing</h5>
      <p>
        The price of the products offered and published on the platform will be
        determined by the sellers. The price of the products and their different
        variants will be available on each product page. Product prices and
        shipping costs are subject to the policies of each seller.
      </p>
      <p>
        Sabmilay will make reasonable efforts to keep the price information
        published on the platform up to date. We encourage you to periodically
        check our platform for updated pricing information.
      </p>
      <p>
        We will always strive to keep prices on the platform accurate. However,
        from time to time pricing errors may occur, including but not limited to
        human, mechanical or similar errors. If a pricing error is discovered,
        the user will be informed of the error. User will have the option to
        reconfirm the order at the correct price. The user also has the option
        to cancel the order if the user is not satisfied with the correct price
        reported on that product order.
      </p>
      <h5>3.5. Payments</h5>
      <p>
        Transactions will be processed through the following payment processors:
      </p>
      <ul>
        <li>Stripe</li>
        <li>Payoneer</li>
      </ul>
      <p>
        The payment information will be processed and collected solely and
        exclusively by the payment processors available on the platform (see our
        privacy policy). The user must pay the price of the product as
        stipulated in the order before the product is shipped and delivered to
        the customer. Payment will be charged to the purchaser's credit/debit
        card immediately upon placing the order for the product(s) purchased. An
        electronic receipt will be issued and sent to the buyer's email after
        each transaction.
      </p>
      <p>
        If you find any inconsistencies in your billing, please contact us
        through our contact details or you can file a complaint through the
        customer service of the relevant payment processor.
      </p>
      <p>
        If your card is declined, you will receive an error message. No payment
        will be charged to your card and no order will be processed. There may
        be a pending transaction on your account until your card issuing bank
        withdraws the authorization. This usually takes 2 to 5 business days.
        Your card may be declined for a variety of reasons, such as insufficient
        funds, AVS (Address Verification System) mismatch or entering an
        incorrect security code.
      </p>
      <p>
        If your payment is declined, you will need to provide an alternative
        payment method or provide another card on which the payment can be
        charged and processed.
      </p>
      <p>
        Your payment details will be treated and protected securely and for the
        sole purpose of processing the purchase of products and will be
        processed, stored and secured by the respective payment processors.
        Sabmilay reserves the right to hire any payment platform available on
        the market, which will process your payment details for the sole purpose
        of processing the purchase of products.
      </p>
      <h5>3.6. Product Descriptions</h5>
      <p>
        Sabmilay attempts to be as accurate as possible. However, Sabmilay does
        not warrant that product descriptions, product prices or other content
        of this platform are accurate, complete, reliable, current or
        error-free. Product descriptions and images may vary depending on the
        color resolution of the user's device screen.
      </p>
      <h1>4. DISCLAIMER</h1>
      <p>
        The products and content posted by sellers are not offered or provided
        by Sabmilay. Sabmilay services are limited to providing the platform to
        facilitate the publication of products by users’ sellers and facilitate
        transactions for the purchase and sale of such products. Sabmilay is not
        at any time responsible for the products and content offered by sellers
        on the platform.
      </p>
      <p>
        Sabmilay is not responsible for the accuracy, safety or legality of
        products or content posted by sellers on Sabmilay. Sabmilay makes no
        representations about the products or content posted by sellers through
        the platform.
      </p>
      <p>
        Sabmilay is not responsible for the quality of the products that sellers
        offer and publish through the platform. The purchase of products posted
        on the platform is at your own risk and responsibility.
      </p>
      <p>
        Disputes arising between users will be resolved by the users themselves.
        Sabmilay not accept any claim in connection with the products or content
        posted on the platform by users’ sellers.
      </p>
      <p>
        Sabmilay not be liable for damages to the physical or moral integrity of
        persons, such as injury, death or any other moral damage such as
        threats, insults and slander that may fall on a natural person, as a
        result of communications established on the platform. The communications
        and relationships established between users as a result of any
        connection within the platform are the sole and exclusive responsibility
        of the users.
      </p>
      <p>
        In the event that one or more users or any third party initiates any
        claim or legal action against another or other users, each and every one
        of the users involved in such claims or actions Sabmilay exempt from any
        liability.
      </p>
      <h5>5. DESCRIPTION OF THE PRODUCTS</h5>
      <p>
        Sabmilay attempts to be as accurate as possible. However, Sabmilay does
        not warrant that platform content and product descriptions are accurate,
        complete, reliable, current or error-free. Product descriptions and
        images and content may vary depending on the color resolution of the
        user's device screen. Any use of the content provided on this platform
        and purchase of the products is at your own risk and responsibility.
      </p>
      <h1>6. THIRD-PARTY MATERIALS.</h1>
      <p>
        “Third-Party Materials” means any content, images, videos, texts or
        other material that is owned by a third party, such as stock images,
        videos and texts. Such Third-Party Materials are subject to the
        applicable third-party terms and licenses, and may only be used as
        permitted by such terms and licenses.
      </p>
      <h1>7. ADVERTISING</h1>
      <p>
        Through the platform, Sabmilay may make available to users, commercial
        and advertising information, own or third parties in accordance with
        good business practices. In these cases, Sabmilay does not endorse,
        guarantee or commit its responsibility for the services and / or
        products marketed by these third parties, since the platform serves as a
        channel of communication and advertising, but not as a tool for the
        provision of services. Consequently, it is the full responsibility of
        the users to access the sites that refer advertising, assuming the
        obligation to verify and know the terms of the services offered by third
        parties.
      </p>
      <h1>8. LICENSE TO USE THE PLATFORM</h1>
      <p>
        Sabmilay gives you a personal, worldwide, royalty-free, non-assignable
        and non-exclusive license to use the platform provided to you by
        Sabmilay as part of the services. This license is for the sole purpose
        of enabling you to use and enjoy the benefit of the services as provided
        by Sabmilay, in the manner permitted by these terms. You may not copy,
        modify, distribute, sell, or lease any part of our services or included
        software, nor may you reverse engineer or attempt to extract the source
        code of that software, unless laws prohibit those restrictions or you
        have our written permission.
      </p>
      <p>
        The user agrees not to use the platform and the services negligently,
        for fraudulent purposes or in an unlawful manner. Likewise, the user
        agrees not to partake in any conduct or action that could damage the
        image, interests or rights of the Sabmilay platform or third parties.
      </p>
      <p>
        This service prohibits sending of messages, that: (1) Any kind of
        messages that are catalogued as SPAM. (2) Are harassing, abusive,
        defamatory, obscene, in bad faith, unethical or otherwise illegal
        content (3) distribute trojans, viruses or other malicious computer
        software (4) Any message with political or religious content (5) Any
        message with obscene or offensive content (6) Are intending to commit
        fraud, impersonating other persons, phishing, scams, or related crime
        (7) distribute intellectual property without ownership or a license to
        distribute such property (8) Breach, in any way, the terms of service,
        privacy policy or rules of this platform or the recipients.
      </p>
      <p>
        Sabmilay reserves the right to terminate your access immediately, with
        or without notice, and without liability to you, if Sabmilay believes
        that you have violated any of these terms or interfered with the use of
        the platform or service by others.
      </p>
      <h1>9. COPYRIGHT</h1>
      <p>
        The images provided by the sellers, belong to the sellers. The copyright
        and intellectual property rights of the images provided by the sellers
        belong to the users who authorize Sabmilay to publish those images on
        the platform. Please do not copy or use these images without the
        respective authorization of the sellers.
      </p>
      <p>
        All materials on Sabmilay, including, without limitation, names, logos,
        trademarks, images, text, columns, graphics, videos, photographs,
        illustrations, software and other elements are protected by copyrights,
        trademarks and/or other intellectual property rights owned and
        controlled by Sabmilay or by third parties that have licensed or
        otherwise provided their material to the platform. You acknowledge and
        agree that all Materials on Sabmilay are made available for limited,
        non-commercial, personal use only. Except as specifically provided
        herein. No material may be copied, reproduced, republished, sold,
        downloaded, posted, transmitted, or distributed in any way, or otherwise
        used for any purpose, by any person or entity, without Sabmilay prior
        express written permission. You may not add, delete, distort, or
        otherwise modify the material. Any unauthorized attempt to modify any
        material, to defeat or circumvent any security features, or to utilize
        Sabmilay or any part of the material for any purpose other than its
        intended purposes is strictly prohibited.
      </p>
      <h1>10. COPYRIGHT INFRINGEMENT (Digital Millennium Copyright Act)</h1>
      <p>
        Sabmilay will respond to all inquiries, complaints and claims regarding
        alleged infringement for failure to comply with or violation of the
        provisions contained in the Digital Millennium Copyright Act. Sabmilay
        respects the intellectual property of others, and expects users to do
        the same. If you believe, in good faith, that any material provided on
        or in connection with the Sabmilay platform infringes your copyright or
        other intellectual property right, please send us your copyright
        infringement request pursuant to Section 512 of the Digital Millennium
        Copyright Act, via our contact information, with the following
        information:
      </p>
      <ul>
        <li>
          Identification of the intellectual property right that is allegedly
          infringed. All relevant registration numbers or a statement regarding
          ownership of the work should be included.
        </li>
        <li>
          A statement that specifically identifies the location of the
          infringing material, in sufficient detail so that Sabmilay can find it
          on the "Sabmilay" platform. Note that providing a top-level URL is not
          sufficient.
        </li>
        <li>Your name, address, telephone number and email address.</li>
        <li>
          A statement by you that you have a good faith belief that the use of
          the allegedly infringing material is not authorized by the copyright
          owner, or its agents, or by law.
        </li>
        <li>
          A statement by you, made under penalty of perjury, that the
          information in your notification is accurate, and that you are the
          copyright owner or authorized to act on its behalf.
        </li>
        <li>
          An electronic or physical signature of the copyright owner or of the
          person authorized to act on the copyright owner's behalf.
        </li>
      </ul>
      <p>
        Upon receipt of a copyright infringement request, Sabmilay will contact
        the allegedly infringing user so that the user can respond to the
        copyright infringement request.
      </p>
      <p>
        Responses to copyright infringement requests must contain the following:
      </p>
      <ul>
        <li>The physical or electronic signature of the user.</li>
        <li>
          The identification of the content that has been removed or the place
          where the content was posted.
        </li>
        <li>
          A statement, under oath, indicating a good faith belief that the
          content or material was removed due to an error.
        </li>
        <li>The name, address and telephone number of the user.</li>
        <li>
          A statement that the user consents to the jurisdiction of the court in
          which the user is located.
        </li>
      </ul>
      <p>
        In the event that the alleged infringing user fails to respond to the
        copyright infringement request and the alleged copyright owner is able
        to satisfactorily prove ownership of such copyright in the content and
        requests removal of such content from the platform, Sabmilay will remove
        the content from the platform immediately.
      </p>
      <p>
        All copyright infringement requests and responses may be submitted
        through our contact information.
      </p>
      <h1>11. USER CONTENT </h1>
      <p>
        Some features of the platform may allow users to provide content and
        information. The user retains copyright or any rights they may have in
        the content they provide through the platform. Sabmilay is not
        responsible for the accuracy, safety or legality of the content posted
        on the platform by users. The user is solely and exclusively responsible
        for its content and the consequences of its publication. By providing
        content and information through the platform, the user grants Sabmilay a
        worldwide, non-exclusive, royalty-free, fully paid right and license to
        host, store, transfer, display, perform, reproduce and modify the user's
        content for the sole purpose of displaying it through the platform and
        to the general public.
      </p>
      <h1>12. USER CONTENT REPRESENTATIONS AND WARRANTIES</h1>
      <p>
        Sabmilay disclaims all liability in connection with the content that the
        user provides through the platform. The user is solely responsible for
        the content and the consequences of providing content through the
        platform. By providing content through the platform, the user affirms,
        represents and warrants that:
      </p>
      <ul>
        <li>
          You are the creator and owner of the content you provide through the
          platform, or have the necessary licenses, rights, consents and
          permissions to authorize Sabmilay to publish and display your content
          through the platform.
        </li>
        <li>
          Your content does not infringe, violate or misappropriate any third
          party right, including any copyright, trademark, patent, trade secret,
          moral right, right of privacy, right of publicity, or any other
          intellectual property or proprietary right; (ii) libel, defame,
          slander, libel, or invade the right of privacy, publicity or other
          proprietary rights of any other person; or (iii) cause Sabmilay to
          violate any law or regulation.
        </li>
        <li>
          Your content could not be considered by a reasonable person to be
          objectionable, profane, indecent, pornographic, harassing,
          threatening, embarrassing, hateful or otherwise inappropriate.
        </li>
        <li>
          Your content does not and will not contain hateful content, threat of
          physical harm or harassment.
        </li>
      </ul>
      <h1>13. USER CONTENT DISCLAIMER</h1>
      <p>
        Sabmilay may, at any time and without prior notice, filter, remove, edit
        or block any user content that, in our judgment, violates these Terms or
        is otherwise objectionable. If a user or content owner notifies us that
        user content allegedly does not conform to these Terms, we may
        investigate the allegation and determine in our sole discretion whether
        to remove the user content, which we reserve the right to do at any time
        and without notice. You acknowledge and agree that Sabmilay reserves the
        right to, and may from time to time, monitor any and all information
        transmitted or received through the service for operational and other
        purposes. If at any time Sabmilay decides to monitor content, Sabmilay
        continues to assume no responsibility or liability for the content or
        any loss or damage incurred as a result of the use of the content.
        During monitoring, information may be examined, recorded, copied and
        used in accordance with our Privacy Policy.
      </p>
      <h1>14. PERSONAL DATA</h1>
      <p>
        Any personal information you post or submit in connection with the
        services and use of the platform will be used in accordance with our
        privacy policy. Please see our privacy policy.
      </p>
      <h1>15. PROHIBITED ACTIVITIES</h1>
      <p>
        The content and information available on the platform (including, but
        not limited to, data, information, text, music, sound, photos, graphics,
        video, maps, icons or other material), as well as the infrastructure
        used to provide such content and information, is proprietary to Sabmilay
        or licensed to the Sabmilay by third parties. For all content other than
        your content, you agree not to otherwise modify, copy, distribute,
        transmit, display, perform, reproduce, publish, license, create
        derivative works from, transfer, or sell or re-sell any information,
        software or services obtained from or through the platform. In addition,
        the following activities are prohibited:
      </p>
      <ul>
        <li>
          Use the services or content for any commercial purpose, outside the
          scope of those commercial purposes explicitly permitted under this
          agreement and related guidelines as made available by Sabmilay.
        </li>
        <li>
          Access, monitor, reproduce, distribute, transmit, broadcast, display,
          sell, license, copy or otherwise exploit any content of the services,
          including but not limited to, using any robot, spider, scraper or
          other automated means or any manual process for any purpose not in
          accordance with this agreement or without our express written
          permission.
        </li>
        <li>
          Violate the restrictions in any robot exclusion headers on the
          services or bypass or circumvent other measures employed to prevent or
          limit access to the services.
        </li>
        <li>
          Take any action that imposes, or may impose, in our discretion, an
          unreasonable or disproportionately large load on our infrastructure.
        </li>
        <li>
          Deep-link to any portion of the services for any purpose without our
          express written permission.
        </li>
        <li>
          "Frame", "mirror" or otherwise incorporate any part of the services
          into any other platforms or service without our prior written
          authorization.
        </li>
        <li>
          Attempt to modify, translate, adapt, edit, decompile, disassemble, or
          reverse engineer any software programs used by Sabmilay in connection
          with the services.
        </li>
        <li>
          Circumvent, disable or otherwise interfere with security-related
          features of the services or features that prevent or restrict use or
          copying of any content.
        </li>
      </ul>
      <h1>16. DISCLAIMER OF WARRANTIES </h1>
      <p>
        Sabmilay will provide its services with reasonable skill and care but
        does not give any guarantees, warranties or representations in respect
        of any other person's services.
      </p>
      <p>
        Because of the nature of the Internet Sabmilay provides and maintains
        the platform on an "as is", "as available" basis and makes no promise
        that use of the platform will be uninterrupted or entirely error free.
        We are not responsible to you if we are unable to provide our Internet
        services for any reason beyond our control.
      </p>
      <p>
        Our platform may from time to time contain links to other web sites
        which are not under the control of and are not maintained by us. These
        links are provided for your convenience only and we are not responsible
        for the content of those platforms.
      </p>
      <p>
        Except as provided above we can give no other warranties, conditions or
        other terms, express or implied, statutory or otherwise and all such
        terms are hereby excluded to the maximum extent permitted by law.
      </p>
      <p>
        You will be responsible for any breach of these terms by you and if you
        use the platform in breach of these terms you will be liable to and will
        reimburse Sabmilay for any loss or damage caused as a result.
      </p>
      <p>
        Sabmilay will not be liable in any amount for failure to perform any
        obligation under this agreement if such failure is caused by the
        occurrence of any unforeseen event beyond its reasonable control
        including without limitation Internet outages, communications outages,
        fire, flood, war or act of God.
      </p>
      <p>
        These terms do not affect your statutory rights as a consumer which are
        available to you.
      </p>
      <p>
        Subject as aforesaid, to the maximum extent permitted by law, Sabmilay
        excludes liability for any loss or damage of any kind howsoever arising,
        including without limitation any direct, indirect or consequential loss
        whether or not such arises out of any problem you notify to Sabmilay and
        Sabmilay shall have no liability to pay any money by way of
        compensation, including without limitation all liability in relation to:
      </p>
      <ul>
        <li>Any incorrect or inaccurate information on the platform.</li>
        <li>
          The infringement by any person of any Intellectual Property Rights of
          any third party caused by their use of the platform.
        </li>
        <li>
          Any loss or damage resulting from your use or the inability to use
          the platform or resulting from unauthorized access to, or alteration
          of your transmissions or data in circumstances which are beyond our
          control.
        </li>
        <li>
          Any loss of profit, wasted expenditure, corruption or destruction of
          data or any other loss which does not directly result from something
          we have done wrong.
        </li>
        <li>
          Any amount or kind of loss or damage due to viruses or other malicious
          software that may infect a user's computer equipment, software, data
          or other property caused by persons accessing or using content
          from the platform or from transmissions via emails or attachments
          received from Sabmilay.
        </li>
        <li>
          All representations, warranties, conditions and other terms which but
          for this notice would have effect.
        </li>
      </ul>
      <h1>17. ELECTRONIC COMMUNICATIONS</h1>
      <p>
        No responsibility will be accepted by Sabmilay for failed, partial or
        garbled computer transmissions, for any computer, telephone, cable,
        network, electronic or internet hardware or software malfunctions,
        failures, connections, availability, for the acts or omissions of any
        user, internet accessibility or availability or for traffic congestion
        or unauthorized human act, including any errors or mistakes.
      </p>
      <h1>18. THIRD PARTY PLATFORMS</h1>
      <p>
        Through your use of the platform and services you may encounter links to
        third party sites or be able to interact with third party sites. Such
        third parties may charge a fee for use of certain content or services
        provided on or by way of their platforms. Therefore, you should make
        whatever investigation you feel is necessary or appropriate before
        proceeding with any transaction with any third party to determine
        whether a charge will be incurred. Where Sabmilay provide details of
        fees or charges for such third-party content or services, such
        information is provided for convenience and information purposes only.
        Any interactions with third party sites and apps are at your own risk.
        You expressly acknowledge and agree that Sabmilay are in no way
        responsible or liable for any such third-party sites.
      </p>
      <h1>19. INDEMNIFICATION</h1>
      <p>
        You agree to defend and indemnify Sabmilay from and against any claims,
        causes of action, demands, recoveries, losses, damages, fines, penalties
        or other costs or expenses of any kind or nature including but not
        limited to reasonable legal and accounting fees, brought by third
        parties as a result of:
      </p>
      <ul>
        <li>
          Your breach of this agreement or the documents referenced herein.
        </li>
        <li>Your violation of any law or the rights of a third party.</li>
        <li>Your use of the Sabmilay platform.</li>
      </ul>
      <h1>20. CHANGES AND TERMINATION</h1>
      <p>
        We may change the platform and these terms at any time, in our sole
        discretion and without notice to you. You are responsible for remaining
        knowledgeable about these terms. Your continued use of the platform
        constitutes your acceptance of any changes to these terms and any
        changes will supersede all previous versions of the terms. Unless
        otherwise specified herein, all changes to these terms apply to all
        users take effect. Furthermore, we may terminate this agreement with you
        under these terms at any time by notifying you in writing (including by
        email) or without any warning.
      </p>

      <h1>21. ASSIGNMENT</h1>
      <p>
        This agreement and any rights and licenses granted hereunder, may not be
        transferred or assigned by you, but may be assigned by Sabmilay without
        restriction.
      </p>
      <h1>22. INTEGRATION CLAUSE</h1>
      <p>
        This agreement together with the privacy policy and any other legal
        notices published by Sabmilay, shall constitute the entire agreement
        between you and Sabmilay concerning and governs your use of the
        platform.
      </p>
      <h1>23. DISPUTES</h1>
      <p>
        The user agrees that any dispute, claim or controversy arising out of or
        relating to these terms and conditions, or the breach, termination,
        enforcement, interpretation or validity thereof or the use of the
        platform, shall be resolved by binding arbitration between the user and
        Sabmilay, provided that each party retains the right to bring an
        individual action in a court of competent jurisdiction.
      </p>
      <p>
        In the event that a dispute arises in connection with the use of the
        platform or breach of these terms and conditions, the parties agree to
        submit their dispute to arbitration resolution before a reputable
        arbitration organization as mutually agreed by the parties and in
        accordance with applicable commercial arbitration rules.
      </p>
      <p>
        You agree to initiate a formal dispute proceeding by sending us a
        communication through our contact information. Sabmilay may choose to
        send you a written offer after receiving your initial communication. If
        we offer and send you a settlement offer and you do not accept the
        offer, or we are unable to resolve your dispute satisfactorily and you
        wish to continue with the dispute process, you must initiate the dispute
        resolution process before an accredited arbitration organization and
        file a separate Demand for Arbitration. Any award rendered by the
        arbitration tribunal shall be final and conclusive on the parties.
      </p>
      <p>
        To the fullest extent permitted by law, you agree that you will not
        file, join or participate in any class action lawsuit in connection with
        any claim, dispute or controversy that may arise in connection with your
        use of the platform and purchase of products posted and offered through
        the listings available on the platform.
      </p>
      <p>
        The courts of the United States, specifically the courts located in the
        state of (Insert State), shall have jurisdiction over any dispute,
        controversy or claim relating to Sabmilay and its business operations.
        Any such dispute or controversy shall be brought and resolved in the
        courts of the United States, specifically the courts located in the
        state of (Insert State).
      </p>
      <h1>24. FINAL PROVISIONS</h1>
      <p>
        These terms and conditions are governed by the laws of the United
        States. Use of the Sabmilay platform is unauthorized in any jurisdiction
        that does not give effect to all provisions of these terms and
        conditions.
      </p>
      <p>
        Our performance of these terms is subject to existing laws and legal
        process, and nothing contained in these Terms limits our right to comply
        with law enforcement or other governmental or legal requests or
        requirements relating to your use of our platform or information
        provided to or gathered by us with respect to such use.
      </p>
      <p>
        If any part of these terms is found to be invalid, illegal or
        unenforceable, the validity, legality and enforceability of the
        remaining provisions will not in any way be affected or impaired. Our
        failure or delay in enforcing any provision of these Terms at any time
        does not waive our right to enforce the same or any other provision(s)
        hereof in the future.
      </p>
      <p>Any rights not expressly granted herein are reserved.</p>
      <h1>25. CONTACT INFORMATION</h1>
      <p>
        If you have questions or concerns about this cookie policy and the
        handling and security of your data, please contact us through our
        contact page or via the contact information below:
      </p>
      <h2>Sabmilay.</h2>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
export const Header = () => {
  const [active, setActive] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <header className="common_width">
      <Link to="/">
        Sab<span>m</span>ilay
      </Link>
      <nav className={`${active && "active"}`}>
        <li>
          <Link to="/">Home</Link>
        </li>

        <li>
          <Link to="/AboutUs">About Us</Link>
        </li>
        <li>
          <Link to="/Features">Our Features</Link>
        </li>
        {/* <li>
          <Link to="/Users">Our Users</Link>
        </li> */}
        <li>
          <Link to="/Team">Our Team</Link>
        </li>
        <li>
          <Link to="/Contact">Contact</Link>
        </li>
      </nav>

      <div
        className="burger_icon"
        onClick={(e) => {
          setActive(!active);
        }}
      >
        <MenuIcon />
      </div>
    </header>
  );
};

import React from "react";
import "../assets/css/Landing.css";

import { Header } from "../components/Header";
import { MainArea } from "../components/MainArea";
import { HowWorks } from "../components/HowWorks";
import { Portfolio } from "../components/Portfolio";

import { Footer } from "../components/Footer";
import { EmailSender } from "../components/EmailSender";
import { TeamCard } from "../components/TeamCard";
import { Link } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import imageteam from "../assets/img/0.jpg";
const Home = () => {
  return (
    <div className="Landing">
      <div className="landing_wrapper">
        <div className="firstcircle circle "></div>
        <div className="secondcircle circle "></div>
        <div className="thirdcircle circle "></div>
        <div className="fourthcircle  circle "></div>

        {/* Header */}
        <Header />

        {/* main */}
        <MainArea />

        <HowWorks />

        <Portfolio />

        {/* 
        <section className="review_wrapper review_wrapper_gap">
          <h1>Our Users</h1>
          <section className="row_area common_width">
            <div className="img_wrapper">
              <img
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                alt=""
              />
            </div>
            <div className="presentation">
              <h1>Foto Sushi</h1>
              <p>
                Consumers use customer reviews during the buyer's journey to
                understand how your product works and whether it’s worth the
                investment.Most everyone can publish a review online, and these
                reviews can contain good information, negative experiences, and
                sometimes even untrue claims about your business.
              </p>
              <div className="stars_wrapper">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
            </div>
          </section>
          <section className="row_area common_width right">
            <div className="presentation">
              <h1>Brooke Cagle</h1>

              <p>
                Consumers use customer reviews during the buyer's journey to
                understand how your product works and whether it’s worth the
                investment.Most everyone can publish a review online, and these
                reviews can contain good information, negative experiences, and
                sometimes even untrue claims about your business.
              </p>

              <div className="stars_wrapper">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
            </div>
            <div className="img_wrapper">
              <img
                src="https://images.unsplash.com/photo-1543269664-7eef42226a21?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                alt=""
              />
            </div>
          </section>
          <section className="row_area common_width">
            <div className="img_wrapper">
              <img
                src="https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
                alt=""
              />
            </div>
            <div className="presentation">
              <h1>Julian Wan</h1>
              <p>
                Consumers use customer reviews during the buyer's journey to
                understand how your product works and whether it’s worth the
                investment.Most everyone can publish a review online, and these
                reviews can contain good information, negative experiences, and
                sometimes even untrue claims about your business.
              </p>
              <div className="stars_wrapper">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
            </div>
          </section>
          <div className="link_wrapper">
            <Link to="/Users"> See More</Link>
          </div>
        </section> */}
      </div>

      <EmailSender />

      <Footer />
    </div>
  );
};
export default Home;

import React from "react";
import "../assets/css/Users.css";

import { Header } from "../components/Header";

import { Footer } from "../components/Footer";
import { EmailSender } from "../components/EmailSender";
const AboutUs = () => {
  return (
    <div className="Landing extend_page">
      <div className="landing_wrapper">
        <div className="firstcircle circle "></div>
        <div className="secondcircle circle "></div>
        <div className="thirdcircle circle "></div>
        <div className="fourthcircle  circle "></div>

        {/* Header */}
        <Header />
        <section
          className="Users  review_wrapper common_width"
          style={{ position: "relative", zIndex: 5 }}
        >
          <h1>Who We Are</h1>
          <p>
            At Sabmilay, our purpose is to provide a platform for businesses to
            grow and customers to engage with zero to no friction. <br /> <br />
            Our platform supports hundreds of businesses ranging from all kinds
            of products and categories. Our mission is to develop and provide
            cutting edge features for businesses to expand, and provide shoppers
            with the best experience as possible in a long-term sustainable
            manner. We’re not just a Social E-Commerce Platform, but a diverse
            community of multiple organizations and people of all cultures and
            backgrounds coming together at one place to make our lives better.
          </p>
          <section className="row_area">
            <div className="img_wrapper">
              <img
                src="https://cdn.pixabay.com/photo/2016/02/07/20/38/computer-1185569_1280.jpg"
                alt=""
              />
            </div>
            <div className="presentation">
              <h1>Our Story</h1>
              <p>
                Sabmilay was formed as a solution to increased friction of
                creating a business online or migrating an existing business
                online. We saw a need of implementing social aspects to an
                ecommerce platform. We wanted businesses and customers to feel
                empowered, so both of them got the best. We wanted to break the
                normal and expected rituals of running businesses. We wanted to
                provide cutting edge technologies to expand businesses, increase
                their sales and revenues, and at the same time deliver the best
                experience to their customers as possible. From what we have
                built and how our users use this platform, we feel proud and
                confident in saying That’s Exactly What We Did! We didn’t want
                Sabmilay to run the show, but hundreds of businesses that make
                up Sabmilay should run the platform, and their customers should
                be their board of directors.
              </p>
            </div>
          </section>
          <section className="row_area  right">
            <div className="presentation">
              <h1>Our Mission</h1>

              <p>
                Our Mission is to provide businesses with the most advanced
                technological tools, so they can expand their businesses and
                customer base to the next level, and at the same time make
                Sabmilay the most social, safe and customer driven platform on
                planet Earth.
              </p>
            </div>
            <div className="img_wrapper">
              <img
                src="https://cdn.pixabay.com/photo/2015/01/08/18/29/entrepreneur-593358_1280.jpg"
                alt=""
              />
            </div>
          </section>

          <section className="row_area">
            <div className="img_wrapper">
              <img
                src="https://cdn.pixabay.com/photo/2018/03/10/12/00/teamwork-3213924_1280.jpg"
                alt=""
              />
            </div>
            <div className="presentation">
              <h1>Core Values</h1>

              <p>
                At our heart, we believe that businesses and customers deserve a
                social, safe and ethical platform, without any cost associated
                and without any strings attached. They should have a platform
                where they could easily and directly engage and interact with
                customers and businesses following our core values. At Sabmilay,
                we believe in TTERP, which stands for:
              </p>
              <ul>
                <li>Transparency</li>
                <li>Team Work</li>
                <li>Ethics</li>
                <li>Responsibility</li>
                <li>Professionalism</li>
              </ul>
              <p>
                We hold these values very near and dear to our heart, and expect
                all employees, employers, customers, businesses and executives
                to follow these principles to create a social, safe, and
                customer-driven platform for all our users.
              </p>
            </div>
          </section>
        </section>
      </div>

      <EmailSender />

      <Footer />
    </div>
  );
};
export default AboutUs;

import React from "react";
import NFT from "../assets/img/NFT.png";
import nft2 from "../assets/img/nft2.png";
import nft3 from "../assets/img/nft3.png";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import InsightsIcon from "@mui/icons-material/Insights";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
export const HowWorks = () => {
  return (
    <section className="how_it_works">
      <div className="content_area common_width">
        <div className="top_area">
          <h1>How it works</h1>
          <p>Checkout the steps below on how to use the Sabmilay application</p>
        </div>
        <div className="boxes_area">
          <div className="box">
            <div className="boximg">
              <AccountCircleOutlinedIcon />
            </div>
            <p>Create account on Sabmilay app</p>
          </div>
          <div className="box">
            <div className="boximg">
              <PermIdentityIcon />
            </div>

            <p>Setup your profile by adding your information.</p>
          </div>
          <div className="box">
            <div className="boximg">
              <InsightsIcon />
            </div>

            <p>You can now post your ads</p>
          </div>
        </div>
      </div>
    </section>
  );
};

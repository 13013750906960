import { BrowserRouter, Routes as Routing, Route } from "react-router-dom";
import AboutUs from "../pages/AboutUs";
import Contact from "../pages/Contact";
import Features from "../pages/Features";
import Home from "../pages/Home";
import { Privacy } from "../pages/Policy/Privacy";
import { Cookie } from "../pages/Policy/Cookie";
import { Refund } from "../pages/Policy/Refund";
import { Shipping } from "../pages/Policy/Shipping";
import { Terms } from "../pages/Policy/Terms";
import Team from "../pages/Team";
import Users from "../pages/Users";

function Routes() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routing>
          <Route path="/" element={<Home />} />
          <Route path="/Team" element={<Team />} />
          <Route path="/Privacy" element={<Privacy />} />
          <Route path="/Users" element={<Users />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Features" element={<Features />} />
          <Route path="/Cookie" element={<Cookie />} />
          <Route path="/Refund" element={<Refund />} />
          <Route path="/Shipping" element={<Shipping />} />
          <Route path="/Terms" element={<Terms />} />
        </Routing>
      </BrowserRouter>
    </div>
  );
}

export default Routes;

import React from "react";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import InstagramIcon from "@mui/icons-material/Instagram";
export const TeamCard = ({ img, name, work }) => {
  return (
    <div className="TeamCard">
      <img src={img} alt="" />
      <h1>{name}</h1>
      <p>{work}</p>

      {/* <ul className="social_media">
        <li>
          <a href="#">
            <TwitterIcon />
          </a>
        </li>
        <li>
          <a href="#">
            <InstagramIcon />
          </a>
        </li>
      </ul> */}
    </div>
  );
};

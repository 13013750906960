import React from "react";

export const ShippingContent = () => {
  return (
    <div className="common_width PrivacyContent">
      <h1>Shipping Policy</h1>
      <h2>Welcome to Sabmilay!</h2>

      <p>
        We appreciate your time for shopping at Sabmilay, this shipping policy
        applies to all products purchased through:
      </p>

      <a href="#">https://sabmilay.com</a>

      <h2 style={{ marginTop: 35 }}>1. SHIPPING INFORMATION</h2>
      <p>
        Sellers who offer and post products on the platform are responsible for
        the shipping and delivery of their products. If sellers use a shipping
        or fulfilment service, they are ultimately responsible for ensuring that
        their buyers receive their orders. Sellers are responsible for
        specifying the shipping costs and processing time for their orders in
        their respective listings.
      </p>
      <p>
        Sellers who offer products on the platform, undertake to deliver the
        product in perfect condition to the address indicated by the buyer
        during the purchase process. In order to optimise delivery, we kindly
        ask the buyer to indicate a safe address where the order can be
        delivered during normal business hours.
      </p>
      <p>
        Please check the shipping policies of each seller before placing an
        order through our platform. Please note that Sabmilay is not responsible
        for shipments of products offered and posted on the platform by sellers.
      </p>

      <h2>2. CONTACT US</h2>
      <p>
        If you have questions or concerns about this cookie policy and the
        handling and security of your data, please contact us through our
        contact page or via the contact information below:
      </p>
      <h2>Sabmilay.</h2>
    </div>
  );
};

import React from "react";
import { Link } from "react-router-dom";
export const Footer = () => {
  return (
    <footer className="landing_footer">
      <div className="common_width">
        <ul>
          <li>
            <Link to="/" className="logo">
              Sabmilay
            </Link>
          </li>
          <li>
            Buy and sell products with satisfaction. Create your own marketplace
            without any cost. Interact and engage with customers and businesses.
            Learn more about your business using a host of features. Try out the
            app today.
          </li>
        </ul>
        <ul>
          <li className="head">Pages</li>
          <li>
            <Link to="/">Home</Link>
          </li>

          <li>
            <Link to="/AboutUs">About Us</Link>
          </li>
          {/* <li>
            <Link to="/Users">Our Users</Link>
          </li> */}
          <li>
            <Link to="/Team">Our Team</Link>
          </li>
          <li>
            <Link to="/Contact">Contact</Link>
          </li>
        </ul>
        <ul>
          <li className="head">Policies</li>
          <li>
            <Link to="/Privacy">Privacy Policy</Link>
          </li>

          <li>
            <Link to="/Cookie">Cookie Policy</Link>
          </li>
          <li>
            <Link to="/Refund">Refund Policy</Link>
          </li>

          <li>
            <Link to="/Shipping">Shipping Policy</Link>
          </li>
          <li>
            <Link to="/Terms">Terms & Conditions</Link>
          </li>
        </ul>
      </div>
    </footer>
  );
};

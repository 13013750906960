import React from "react";
import "../assets/css/Users.css";

import { Header } from "../components/Header";

import { Footer } from "../components/Footer";
import { EmailSender } from "../components/EmailSender";

import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
const Users = () => {
  return (
    <div className="Landing extend_page">
      <div className="landing_wrapper">
        {/* Header */}
        <Header />

        <section className="Users  review_wrapper common_width">
          <h1>Our Users Reviews</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore
            dolorem ducimus voluptatum ipsam laudantium vel eveniet
            necessitatibus officia! Illo sequi tempora quia dolore, quaerat
            eaque dolores cumque incidunt ullam maxime!
          </p>
          <section className="row_area">
            <div className="img_wrapper">
              <img
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                alt=""
              />
            </div>
            <div className="presentation">
              <h1>Foto Sushi</h1>
              <p>
                Consumers use customer reviews during the buyer's journey to
                understand how your product works and whether it’s worth the
                investment.Most everyone can publish a review online, and these
                reviews can contain good information, negative experiences, and
                sometimes even untrue claims about your business.
              </p>
              <div className="stars_wrapper">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
            </div>
          </section>
          <section className="row_area  right">
            <div className="presentation">
              <h1>Brooke Cagle</h1>

              <p>
                Consumers use customer reviews during the buyer's journey to
                understand how your product works and whether it’s worth the
                investment.Most everyone can publish a review online, and these
                reviews can contain good information, negative experiences, and
                sometimes even untrue claims about your business.
              </p>

              <div className="stars_wrapper">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
            </div>
            <div className="img_wrapper">
              <img
                src="https://images.unsplash.com/photo-1543269664-7eef42226a21?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                alt=""
              />
            </div>
          </section>
          <section className="row_area ">
            <div className="img_wrapper">
              <img
                src="https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1180&q=80"
                alt=""
              />
            </div>
            <div className="presentation">
              <h1>Julian Wan</h1>
              <p>
                Consumers use customer reviews during the buyer's journey to
                understand how your product works and whether it’s worth the
                investment.Most everyone can publish a review online, and these
                reviews can contain good information, negative experiences, and
                sometimes even untrue claims about your business.
              </p>
              <div className="stars_wrapper">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
            </div>
          </section>

          <section className="row_area  right">
            <div className="presentation">
              <h1>Emmanuel Ikwuegbu</h1>

              <p>
                Consumers use customer reviews during the buyer's journey to
                understand how your product works and whether it’s worth the
                investment.Most everyone can publish a review online, and these
                reviews can contain good information, negative experiences, and
                sometimes even untrue claims about your business.
              </p>

              <div className="stars_wrapper">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
            </div>
            <div className="img_wrapper">
              <img
                src="https://images.unsplash.com/photo-1624574337423-7ea725c5540c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1738&q=80"
                alt=""
              />
            </div>
          </section>
          <section className="row_area ">
            <div className="img_wrapper">
              <img
                src="https://images.unsplash.com/photo-1474176857210-7287d38d27c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                alt=""
              />
            </div>
            <div className="presentation">
              <h1>Aatik Tasneem</h1>
              <p>
                Consumers use customer reviews during the buyer's journey to
                understand how your product works and whether it’s worth the
                investment.Most everyone can publish a review online, and these
                reviews can contain good information, negative experiences, and
                sometimes even untrue claims about your business.
              </p>
              <div className="stars_wrapper">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
            </div>
          </section>

          <section className="row_area  right">
            <div className="presentation">
              <h1>Jonas Kakaroto</h1>

              <p>
                Consumers use customer reviews during the buyer's journey to
                understand how your product works and whether it’s worth the
                investment.Most everyone can publish a review online, and these
                reviews can contain good information, negative experiences, and
                sometimes even untrue claims about your business.
              </p>

              <div className="stars_wrapper">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarHalfIcon />
              </div>
            </div>
            <div className="img_wrapper">
              <img
                src="https://images.unsplash.com/photo-1557862921-37829c790f19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1742&q=80"
                alt=""
              />
            </div>
          </section>
          <section className="row_area ">
            <div className="img_wrapper">
              <img
                src="https://images.unsplash.com/photo-1599110906885-b024c90c2773?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                alt=""
              />
            </div>
            <div className="presentation">
              <h1>Paul Hanaoka</h1>
              <p>
                Consumers use customer reviews during the buyer's journey to
                understand how your product works and whether it’s worth the
                investment.Most everyone can publish a review online, and these
                reviews can contain good information, negative experiences, and
                sometimes even untrue claims about your business.
              </p>
              <div className="stars_wrapper">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarHalfIcon />
              </div>
            </div>
          </section>

          <section className="row_area  right">
            <div className="presentation">
              <h1>UX Indonesia</h1>

              <p>
                Consumers use customer reviews during the buyer's journey to
                understand how your product works and whether it’s worth the
                investment.Most everyone can publish a review online, and these
                reviews can contain good information, negative experiences, and
                sometimes even untrue claims about your business.
              </p>

              <div className="stars_wrapper">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarHalfIcon />
              </div>
            </div>
            <div className="img_wrapper">
              <img
                src="https://images.unsplash.com/photo-1587038787166-becd08a156f7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                alt=""
              />
            </div>
          </section>
          <section className="row_area ">
            <div className="img_wrapper">
              <img
                src="https://images.unsplash.com/photo-1609543408632-dc08c29dd94a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                alt=""
              />
            </div>
            <div className="presentation">
              <h1>Roy Javier</h1>
              <p>
                Consumers use customer reviews during the buyer's journey to
                understand how your product works and whether it’s worth the
                investment.Most everyone can publish a review online, and these
                reviews can contain good information, negative experiences, and
                sometimes even untrue claims about your business.
              </p>
              <div className="stars_wrapper">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarHalfIcon />
              </div>
            </div>
          </section>
        </section>
      </div>

      <EmailSender />

      <Footer />
    </div>
  );
};
export default Users;

import React, { useState } from "react";
import "../assets/css/Contact.css";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import main_mbl from "../assets/img/premium.svg";
import { locations } from "../util/locations";
const Contact = () => {
  const [DropdownFirst, setDropdownFirst] = useState(false);
  const [DropdownSecond, setDropdownSecond] = useState(false);
  const [SecondHead, setSecondHead] = useState(
    "Submissions And Recommendations"
  );
  const [FirstHead, setFirstHead] = useState("Select Countries / Territories");

  return (
    <div className="Landing extend_page">
      <div className="landing_wrapper">
        <div className="firstcircle circle "></div>
        <div className="thirdcircle circle "></div>
        {/* Header */}
        <Header />
        <section
          className="Users  review_wrapper common_width"
          style={{ position: "relative", zIndex: 5 }}
        >
          <h1>Contact Us</h1>

          <div className="contact-area">
            <div className="left-side">
              <form
                action="mailto:contactus@sabmilay.com?subject=contact-message"
                enctype="text/plain"
                method="POST"
              >
                <div className="input-wrapper">
                  <label for="name">Your Name (Required)</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="eg: Buju Banton"
                  />
                </div>
                <div className="input-wrapper">
                  <label for="email">Your Email (Required)</label>
                  <input
                    type="email"
                    id="name"
                    name="email"
                    placeholder="info@gmail.com"
                  />
                </div>

                <div className="input-wrapper">
                  <label for="Message">Message (Required)</label>
                  <textarea
                    name="message"
                    placeholder="Write Your Message Here.."
                    id="Message"
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>

                <div className="input-wrapper button-wrapper">
                  <button id="submit_button">SUBMIT</button>
                </div>
              </form>
            </div>

            <div className="right-side">
              <img src={main_mbl} alt="" />
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};
export default Contact;

import React from "react";
import { Header } from "../../components/Header";
import { EmailSender } from "../../components/EmailSender";
import { Footer } from "../../components/Footer";
import { RefundContent } from "../../components/RefundContent";
export const Refund = () => {
  return (
    <div className="Landing">
      <div className="landing_wrapper">
        {/* Header */}
        <Header />
        <RefundContent />
      </div>

      <EmailSender />

      <Footer />
    </div>
  );
};

import React from "react";
import "../assets/css/Users.css";

import { Header } from "../components/Header";
import main_mbl from "../assets/img/mobile_first.svg";
import chat from "../assets/img/chat.svg";
import analytics_real from "../assets/img/analytics_real.svg";
import bussiness_state from "../assets/img/bussiness_state.svg";

import { Footer } from "../components/Footer";
import { EmailSender } from "../components/EmailSender";
import profile1 from "../assets/img/profile 1.svg";
import suggestion from "../assets/img/suggestion.svg";
import premium from "../assets/img/premium.svg";

import profile from "../assets/img/profile.svg";

const Features = () => {
  return (
    <div className="Landing extend_page features">
      <div className="landing_wrapper">
        <div className="firstcircle circle "></div>
        <div className="secondcircle circle "></div>
        <div className="thirdcircle circle "></div>
        <div className="fourthcircle  circle "></div>
        {/* Header */}
        <Header />

        <section
          className="Users  review_wrapper common_width"
          style={{ position: "relative", zIndex: 5 }}
        >
          <h1>Our Features</h1>
          <p>
            Sabmilay provides best features for you so that you can buy or sell
            easily
          </p>
          <section className="row_area">
            <div className="img_wrapper">
              <img src={profile1} alt="" />
            </div>
            <div className="presentation">
              <h1>Online Business Store</h1>
              <p>
                Easily take your existing business online or create a new online
                store, where you’ll have a greater market reach with your
                products, and much more effective marketing tools at your
                disposal. Easily add products in a few fast and simple steps.
                Alert your subscribers using notifications whenever you add a
                new product or discount a new product.
                <br />
                Browse and filter businesses and products to find what you’re
                looking for. Connect with your customers and businesses by using
                Realtime Chat Features. View businesses statistics and analytics
                using our Realtime Analytics Engine, and unlock so much more
                with Sabmilay Premium to take your business to the next level.
              </p>
            </div>
          </section>

          <section className="row_area right">
            <div className="presentation">
              <h1>Home Feed</h1>
              <p>
                Each user of Sabmilay is greeted with a custom home feed where
                we use machine learning and custom algorithms to learn about the
                products and business, you’re most interested in and searched,
                so we could provide you with the best products and deals similar
                to what you’re looking for. Explore the best products and deals
                or shop by the categories you love, the Home Feed will provide
                you with the best personal experience so you never miss a beat.
              </p>
            </div>
            <div className="img_wrapper">
              <img src={suggestion} alt="" />
            </div>
          </section>
          <section className="row_area ">
            <div className="img_wrapper">
              <img src={chat} alt="" />
            </div>
            <div className="presentation">
              <h1>Realtime Chat</h1>

              <p>
                Connect with your customers and other businesses easily, and
                enable Vanish Mode for secure incognito conversations. You can
                share locations, images and files instantly and securely as
                every message is encrypted. We’ve built Vanish Mode for
                increased privacy where all your messages will be automatically
                deleted as soon as you leave the conversation.
              </p>
            </div>
          </section>

          <section className="row_area right">
            <div className="presentation">
              <h1>Sabmilay Premium</h1>

              <p>
                Sabmilay Premium is a premium subscription service offered to
                businesses that want to have a greater outlook and edge over the
                market and deep insights to their businesses.
              </p>
              <ul>
                <li>
                  Promote and advertise your business and products so they get
                  featured first.
                </li>
                <li>
                  View market trends and stats so you curate your business
                  accordingly.
                </li>
                <li>
                  Discover the top products and businesses that are similar to
                  yours, giving you an edge to improve your online business and
                  create connections.
                </li>
                <li>List more products according to your business needs.</li>
              </ul>
              <p>And so much more!</p>
            </div>
            <div className="img_wrapper">
              <img src={premium} alt="" />
            </div>
          </section>
          <section className="row_area ">
            <div className="img_wrapper">
              <img src={bussiness_state} alt="" />
            </div>
            <div className="presentation">
              <h1>Business Statistics</h1>
              <p>
                Using our custom in-house built algorithms, Sabmilay can easily
                provide you with geographical and demographical data about your
                business and products, without revealing any sensitive data, in
                the palm of your hands. Using this data, you can target a
                specific audience and a specific geographic where your product
                is sold the most. This data is collected and mined from over
                hundreds and thousands of businesses to give you the most
                accurate data depiction as possible. These tools were impossible
                to imagine even a few years ago, and now they’re all yours!
              </p>
            </div>
          </section>
          <section className="row_area right">
            <div className="presentation">
              <h1>Realtime Analytics Engine</h1>
              <p>
                We’ve built a Realtime Analytics Engine using state of the art
                powerful Artificial Intelligence and Machine Learning tools, so
                you could easily view your profile views, business subscribers,
                price alerted products, ratings, total minutes your profile was
                viewed, likes and dislikes, along with so much more rich data,
                of your business and each product respectively in a beautiful
                interactive graphical format.
              </p>
            </div>
            <div className="img_wrapper">
              <img src={analytics_real} alt="" />
            </div>
          </section>

          <section className="row_area">
            <div className="img_wrapper">
              <img src={main_mbl} alt="" />
            </div>
            <div className="presentation">
              <h1>Shopping Reimagined</h1>
              <p>
                Search from a catalogue of business stores and products. Filter
                out products from a variety of options and locations, and find
                the best product you’re looking for, using our in-house built
                machine learning algorithms.
              </p>
            </div>
          </section>
        </section>
      </div>

      <EmailSender />

      <Footer />
    </div>
  );
};
export default Features;

import React from "react";

export const RefundContent = () => {
  return (
    <div className="common_width PrivacyContent">
      <h1>Return Policy</h1>
      <h2>Welcome to Sabmilay!</h2>

      <p>This return policy applies to all products purchased through:</p>

      <a href="#">https://sabmilay.com</a>

      <h2 style={{ marginTop: 35 }}>
        1. RETURNS AND REFUNDS (FOR BUYERS ONLY)
      </h2>
      <p>
        Returns and refunds are the sole responsibility of the selling users,
        depending on the products offered and sold through Sabmilay. Sellers
        must inform buyers in a clear and visible way about the refund and
        return information of their products.
      </p>
      <p>
        Sellers will determine in which cases returns and refunds will be made
        and the deadlines for making them effective. Please check with the
        seller's return and refund policy before placing any order.
      </p>
      <p>
        The responsibility for making returns and refunds of the products
        offered through the platform is the sole responsibility of the sellers.
      </p>
      <p>
        Sabmilay does not make returns or refunds, nor does it accept claims for
        refunds or returns of products from buyers. Claims for refunds and
        product returns must be made through the seller and their contact
        details.
      </p>
      <h2>2. SUBSCRIPTIONS (FOR SELLERS ONLY)</h2>
      <p>
        Due to the nature of the services offered through the platform (digital
        services), all purchases of our subscriptions are final and
        non-refundable. The user accepts that, once the purchase and
        registration process is completed and the subscription activated, the
        subscription cannot be refunded. Please check the features and content
        of subscriptions before purchasing a subscription.
      </p>
      <p>
        Subscriptions will automatically renew for an additional period unless
        cancelled before the next payment. The user may cancel the subscription
        at any time and access to the paid features will remain available until
        the next billing date, when it will be permanently suspended.
        Subscriptions can be cancelled through the platform or by sending us
        your cancellation request via our contact information.
      </p>
      <p>
        Sabmilays will only issue a refund if you have been incorrectly charged
        for any subscription fees due to a technical issue with our platform or
        our payment platform. If you find any inconsistencies in your billing,
        please contact the customer service of the relevant payment platform
        (Stripe, Payoneer) or contact us via our contact information for further
        information and assistance.
      </p>
      <p>
        Please note, we do not issue refunds based on seller sales records.
        Subscription fees are charged for access to the tools and benefits
        within the platform.
      </p>

      <h2>3. CONTACT US</h2>
      <p>
        If you have questions or concerns about this cookie policy and the
        handling and security of your data, please contact us through our
        contact page or via the contact information below:
      </p>
      <h2>Sabmilay.</h2>
    </div>
  );
};

import React from "react";
import { Header } from "../../components/Header";
import { EmailSender } from "../../components/EmailSender";
import { Footer } from "../../components/Footer";
import { PrivacyContent } from "../../components/PrivacyContent";
import "../../assets/css/Policy.css";
export const Privacy = () => {
  return (
    <div className="Landing">
      <div className="landing_wrapper">
        {/* Header */}
        <Header />

        <PrivacyContent />
      </div>

      <EmailSender />

      <Footer />
    </div>
  );
};

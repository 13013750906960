import React from "react";

export const EmailSender = () => {
  return (
    <section className="join_wrapper">
      <div className="content_area common_width">
        <h1>Contact Us</h1>

        <form
          className="input_wrapper"
          action="mailto:contactus@sabmilay.com?subject=contact-message"
          enctype="text/plain"
          method="POST"
        >
          <input type="email" name="email" placeholder="Enter your email" />
          <button>Send</button>
        </form>
      </div>
    </section>
  );
};

import React from "react";
import apple_logo from "../assets/img/apple.svg";
import play_store_logo from "../assets/img/google.svg";
import main_mbl from "../assets/img/mobile_first.svg";
import premium from "../assets/img/premium.svg";
export const MainArea = () => {
  return (
    <main className="common_width MainArea">
      <div className="presentation_area">
        <h1>A Social E-Commerce Platform Built Just For You</h1>
        <img src={premium} alt="" className="mbl_mobile_main" />
        <p>
          Buy and sell products with satisfaction. Create your own marketplace
          without any cost. Interact and engage with customers and businesses.
          Learn more about your business using a host of features. Try out the
          app today.
        </p>
        <div
          className="input_wrapper button_wrapper"
          style={{ padding: 0, background: "transparent" }}
        >
          <a href="#" target="_blank">
            <img src={apple_logo} alt="" />
          </a>
          <a href="#" target="_blank">
            <img src={play_store_logo} alt="" />
          </a>
        </div>
      </div>
      <div className="image_wrapper">
        <img src={premium} alt="" />
      </div>
    </main>
  );
};
